import '@amzn/awsui-components-react/index.css';
import './logDetailsTable.css';
import React from 'react';
import InfoTable from './infoTable';
import { LogEntry } from './types';

type Props = {
    logs: LogEntry[],
    logTableTitle: string,
    loadingData: boolean,
}

const LogDetailsTable : React.FC<Props> = ({logs, logTableTitle, loadingData}) => {

    let columnDefinitions = [
        {
            id: "timestamp",
            field: "timestamp",
            header: "Timestamp",
            minWidth: 150,
            cell: (item: any)  => <p>{item.timestamp}</p>
        },
        {
            id: "log",
            field: "log",
            header: "Log",
            cell: (item: any)  => <p>{item.log}</p>
        }
    ]
    
    return (
        <div>
            <InfoTable
                items={logs}
                header={logTableTitle}
                columnDefinitions={columnDefinitions}
                filteringPlaceholder='Search logs'
                loadingData={loadingData}
                shouldDisplayExportBtn={false}
                tablePagination={true}
                tableClassName="logsDetailsTable"
                pageSize={50}
            />
        </div>
    )
}

export default LogDetailsTable;
