import React, { useEffect, useState } from 'react';
import { Flashbar, Spinner } from '@amzn/awsui-components-react';
import JSONPretty from 'react-json-pretty';

import { Broker, ClusterStatus } from '../types';
import { getRabbitClusterStatus } from '../api/api';
import CopyToClipboardButton from '../copyToClipboardButton';

type Props = {
    broker: Broker,
}

const ClusterStatusTab : React.FC<Props> = ({broker}) => {
    const [clusterStatus, setClusterStatus] = useState<ClusterStatus | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitClusterStatus(broker.id, broker.brokerInfo.cellId).then(response => {
            setClusterStatus(response);
        }).catch(error => {
            console.error(error);
            setError("Could not load cluster status");
        }).finally(() => {
            setLoading(false);
        });
    
    }, [broker.id]);

    return (
        <div>
            {error && <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar>}
            {loading && <Spinner /> }
            {!loading && !error && clusterStatus &&
                <div>
                    <CopyToClipboardButton text={JSON.stringify(clusterStatus)} />
                    <JSONPretty data={clusterStatus}></JSONPretty>
                </div>
            } 
        </div>
    );
}

export default ClusterStatusTab;