import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage/mainPage';
import DescribeBroker from './DescribeBroker/describeBroker';
import './common.css';
import PageNotFound from './PageNotFound';

const App : React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/describeBroker" element={<DescribeBroker />} />
                <Route path="/broker/:brokerId" element={<DescribeBroker />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;