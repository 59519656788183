import './describeBroker.css';
import './logsTable.css';
import React, { useEffect, useState } from 'react';
import {
    Tabs
} from '@amzn/awsui-components-react';
import LogsTable from "./logsTable";
import PerInstanceLogs from "./perInstanceLogs"
import { Broker, LogStream } from './types';
import { getLogStreams } from './api/api';

type Props = {
    broker: Broker,
}

const LogsPage : React.FC<Props> = ({broker}) => {
    
    const [loadingStreamData, setLoadingStreamData] = useState<boolean>(false);
    const [logStreams, setLogStreams] = useState<LogStream[]>([]);

    useEffect(() => {
        setLoadingStreamData(true);
        getLogStreams(broker.id, broker.snapshotId).then(response => {
            setLogStreams(response.logStreams);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingStreamData(false);
        })
    }, []);

    const tabs = [
        {
            label: 'Log Streams',
            id: 'logStreams',
            content: <LogsTable
                broker={broker}
                logStreamNames={logStreams}
                loadingStreamNames={loadingStreamData}
            />
        },
        {
            label: 'Per-Instance Logs',
            id: 'perInstanceLogs',
            content: <PerInstanceLogs
                brokerId={broker.id}
                snapshotId={broker.snapshotId}
                region={broker.region}
                logStreamNames={logStreams}
                loadingStreamData={loadingStreamData}
            />
        }
    ];

    return (
        <Tabs tabs={tabs} activeTabId={'logStreams'}/>
    )

}

export default LogsPage;
