import './describeBroker.css';
import './logsTable.css';
import React from 'react';
import {
    Autosuggest,
    Button,
    FormSection,
    TokenGroup,
    DatePicker,
    TimeInput,
    FormField
} from '@amzn/awsui-components-react';
import LogDetailsTable from './logDetailsTable';
import PropTypes from 'prop-types';


class PerInstanceLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logStreamNames: [],
            loadingStreamData: false,
            logs: [],
            loadingLogData: false,
            selectedLogs: [],
            dateTimeRange:{
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: ""
            }
        };

        this.getAllLogItems = this.getAllLogItems.bind(this);
        this.handleAutoSelectChange = this.handleAutoSelectChange.bind(this);
        this.getLogs = this.getLogs.bind(this);
        this.constructDateFromStr = this.constructDateFromStr.bind(this);
    }

    componentDidMount() {
        this.setInitialDateTimes();
    }

    setInitialDateTimes(){
        const currentDate = new Date();
        const dateHourAgo = new Date(currentDate.getTime() - (1000*60*60));
        this.setState({
            dateTimeRange: {
                startDate: dateHourAgo.toISOString().split('T')[0].toString(),
                startTime: dateHourAgo.toISOString().split("T")[1].substr(0, 5),
                endDate: currentDate.toISOString().split('T')[0].toString(),
                endTime: currentDate.toISOString().split("T")[1].substr(0, 5)
            }
        });

    }

    getAllLogItems() {
        let uniqueItems = {};

        this.props.logStreamNames.forEach(
            item => {
                if(!uniqueItems.hasOwnProperty(item.logGroupName)) {
                    uniqueItems[item.logGroupName] = [item.title]
                }
                else{
                    if(uniqueItems[item.logGroupName].indexOf(item.title) <0) {
                        uniqueItems[item.logGroupName].push(item.title)
                    }
                }
            }
        )

        return uniqueItems;
    }

    getButtonItems(logs) {
        let buttonItems = []
        for (const [key, value] of Object.entries(logs)) {
            buttonItems.push({
                label: key,
                options: value.map(logName=> {
                    return {value: logName}
                    }
                )

            });
        }

        return buttonItems;
    }

    handleAutoSelectChange(event) {
        if(event.selectedOption) {
            if(!this.state.selectedLogs.includes(event.selectedOption.value)) {
                this.setState({
                    selectedLogs: [...this.state.selectedLogs, event.selectedOption.value]
                });
            }
        }
    }

    createTokenGroup(){
        let tokenGroup = []
        this.state.selectedLogs.forEach(
            log => {
                tokenGroup.push(
                    {
                        label: log,
                        dismissLabel: `Remove Item: ${log}`
                    }
                )
            })
        return tokenGroup;
    }

    getLogs() {
        if(this.state.selectedLogs.length < 1){
            return;
        }
        this.setState({
            loadingLogData: true
        });

        fetch('/protected-data/per-instance-logs?brokerId=' + this.props.brokerId + "&snapshotId=" + this.props.snapshotId +
            '&logNames=' + this.state.selectedLogs.join(",") +
            "&startTime="+this.constructDateFromStr(this.state.dateTimeRange.startDate, this.state.dateTimeRange.startTime)+
            "&endTime="+this.constructDateFromStr(this.state.dateTimeRange.endDate, this.state.dateTimeRange.endTime)+
            "&domain=" + this.props.domain +
            "&region=" + this.props.region, { credentials: 'include' })
            .then(response =>
                response.text()
            )
            .then(responseData => {
                let dataJson = JSON.parse(responseData);
                this.setState({
                    logs: dataJson,
                    loadingLogData: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    logs: [],
                    loadingLogData: false
                }
                )
            });
    }

    getColumnDefinitions(){
        if(this.state.logs.length < 1){
            return [
                {
                    id: "log",
                    header: "Logs"
                }
            ];
        }

        let columnDefinitions = [
            {
                id: "timestamp",
                header: "Time Stamp"
            }
        ]

        for (const [key, value] of Object.entries(this.state.logs[0].instanceLogs)) {
            columnDefinitions.push(
                {
                    id: "instanceLogs."+key,
                    header: key,
                })
        }
        return columnDefinitions;

    }

    constructDateFromStr(date, time) {
        return date+"T"+time+":00Z";
    }

    render() {

        let logDetailsColumnDefinitions = this.getColumnDefinitions();

        return(
            <div>
                <FormSection
                    header="Log Selector"
                    footer={
                        <Button
                            variant="primary" onClick={this.getLogs}>
                            Generate Logs
                        </Button>
                    }
                >
                    <div className={"LogSelector"}>
                        <Autosuggest
                            onChange={({ detail }) => this.handleAutoSelectChange(detail)}
                            options={this.getButtonItems(this.getAllLogItems())}
                            placeholder="Enter Name of Log Group"
                            empty="No matches found"
                        />
                        <TokenGroup
                            onDismiss={({detail: {itemIndex}}) => {
                                this.setState({
                                    selectedLogs: [
                                        ...this.state.selectedLogs.slice(0, itemIndex),
                                        ...this.state.selectedLogs.slice(itemIndex + 1)
                                    ]
                                });
                            }}
                            items={this.createTokenGroup()}
                        />
                    </div>


                    <FormField
                        description="* All times are in UTC"
                        label="Time Range selector"
                    >

                            <div>
                                 Start Date Time:
                            </div>
                            <DatePicker
                                value={this.state.dateTimeRange.startDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                        ? `, selected date is ${selectedDate}`
                                        : "")
                                }
                                onChange={({ detail }) =>
                                    this.setState(state => (state.dateTimeRange.startDate = detail.value, state))
                                }
                            />
                            <TimeInput className={"startTimeInput"}
                                value={this.state.dateTimeRange.startTime}
                                onChange={({ detail }) =>
                                    this.setState(state => (state.dateTimeRange.startTime = detail.value, state))
                                }
                                format="hh:mm"
                                placeholder="hh:mm"
                            />
                            <div>
                                End Date Time:
                            </div>
                            <DatePicker
                                value={this.state.dateTimeRange.endDate}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose Date" +
                                    (selectedDate
                                        ? `, selected date is ${selectedDate}`
                                        : "")
                                }
                                onChange={({ detail }) =>
                                    this.setState(state => (state.dateTimeRange.endDate = detail.value, state))
                                }
                            />

                            <TimeInput className={"endTimeInput"}
                                value={this.state.dateTimeRange.endTime}
                                onChange={({ detail }) =>
                                    this.setState(state => (state.dateTimeRange.endTime = detail.value, state))
                                }
                                format="hh:mm"
                                placeholder="hh:mm"
                            />

                    </FormField>
                </FormSection>

                <LogDetailsTable
                    logs={this.state.logs}
                    logTableTitle={"Per-Instance Logs"}
                    loadingData={this.state.loadingLogData}
                    columnDefinitions={logDetailsColumnDefinitions}
                />

            </div>

        )
    }

}

PerInstanceLogs.propTypes = {
    loadingStreamData: PropTypes.bool,
    logStreamName: PropTypes.string
};


export default PerInstanceLogs;
