import './describeBroker.css';
import React, { useEffect, useState } from 'react';
import InfoTable from './infoTable';
import { getFailoverHistory } from './api/api';
import { Broker, QualifiedFailoverEvent } from './types';


type Props = {
    broker: Broker,
    snapshotId: string,
}

const FailoverHistoryTable : React.FC<Props> = ({broker, snapshotId}) => {

    const [failoverHistory, setFailoverHistory] = useState<QualifiedFailoverEvent[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    useEffect(() => {
        getFailoverHistory(broker.id, snapshotId).then(response => {
            setFailoverHistory(response.failoverHistory);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingData(false);
        })
    }, []);

    let columnDefinitions = [
        {
            id: "arcUpdateTime",
            header: "ARC Update Time",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.arcUpdateTime
        },
        {
            id: "initiationTime",
            header: "Initiation Time",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.initiationTime
        },
        {
            id: "completionTime",
            header: "Completion Time",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.completionTime
        },
        {
            id: "mode",
            header: "Mode",
            cell: (item: QualifiedFailoverEvent) => item.failoverEvent.mode
        },
        {
            id: "roleAfterFailover",
            header: "Role after Failover",
            cell: (item: QualifiedFailoverEvent) => item.roleAfterFailover
        }
    ]

    let sortableColumns = [
        {
            id: "arcUpdateTime",
            field: "arcUpdateTime"
        },
        {
            id: "initiationTime",
            field: "initiationTime"
        },
        {
            id: "completionTime",
            field: "completionTime"
        },
        {
            id: "mode",
            field: "mode"
        }
    ]

    return (
        <div className="infoTable">
            <InfoTable
                items={failoverHistory}
                header='Failover History'
                columnDefinitions={columnDefinitions}
                loadingData={loadingData}
                filteringPlaceholder='Search event'
                sortableColumns={sortableColumns}
                shouldDisplayExportBtn={false}
            />
        </div>
    );

}

export default FailoverHistoryTable;
