import React from 'react';
import './describeBroker.css';
import BrokerAlarmsTable from './brokerAlarmsTable';
import InstanceHistoryTable from './instanceHistoryTable';
import { Broker, Ec2Instance, InstanceHistory } from './types';
import BrokerInfoTable from './brokerInfoTable';
import BrokerInstanceInfoTable from './brokerInstanceInfoTable';
import BrokerMaintenanceInfoTable from './brokerMaintenanceInfoTable';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[] | undefined,
    instanceHistory: InstanceHistory[],
}

const BrokerDetailsTable : React.FC<Props> = ({broker, brokerInstances, instanceHistory}) => {

    return (
        <div className="brokerDetailsTable">
            <div key="broker-alarms">
                <BrokerAlarmsTable
                    brokerAlarms={broker.brokerInfo.brokerAlarms}
                />
            </div>
            <div key="broker-info">
                <BrokerInfoTable
                    brokerInfo={broker.brokerInfo}
                    brokerInstances={brokerInstances}
                />
            </div>
            <div key="broker-instance-info">
                <BrokerInstanceInfoTable
                    broker={broker}
                    brokerInstances={brokerInstances}
                />
            </div>
            <div key="broker-maintenance-info">
                <BrokerMaintenanceInfoTable
                    brokerMaintenanceInfo={broker.maintenanceInfo}
                />
            </div>
            {instanceHistory.length > 0 &&
                <div key="instance-history">
                    <InstanceHistoryTable
                        instanceHistory={instanceHistory}
                    />
                </div>
            }
        </div>
    )

}

export default BrokerDetailsTable;
