import React from 'react';
import '@amzn/awsui-components-react/index.css';
import { ColumnLayout, Spinner } from '@amzn/awsui-components-react';
import { BrokerInfo, Ec2Instance, Ec2Volume, Link, NodeMapping } from './types';
import { renderField, renderLink, renderElement } from './tableUtils';

type Props = {
    brokerInfo: BrokerInfo,
    brokerInstances: Ec2Instance[] | undefined
}

function renderNodeMapping(nodeMapping: NodeMapping | undefined): React.ReactNode {
    if (nodeMapping === undefined) {
        return <Spinner />
    } else {
        let ret: React.ReactElement[] = [];
        for (const instanceId in nodeMapping) {
            ret.push(<p>{instanceId + " --> " + nodeMapping[instanceId]}</p>);
        }
        return ret;
    }
}

function brokerNodeMappingCopyText(nodeMapping: NodeMapping | undefined): string {
    if (nodeMapping) {
        return Object.entries(nodeMapping)
            .map((instanceId, privateIp) => `${instanceId} --> ${privateIp}`)
            .join("\n");
    }
    return "";
}

function createEBSDataPartitionLink(brokerInstances: Ec2Instance[] | undefined): Link | undefined {
    if (brokerInstances === undefined) {
        return undefined;
    }

    if (brokerInstances.length === 0) {
        return undefined;
    }

    let firstInstance = brokerInstances[0];
    let volumes : Ec2Volume[] = firstInstance.volumes.filter(v => v.size != 32);
    if (volumes.length === 0) {
        return undefined;
    }
    let firstVolume = volumes[0];
    return {
        name: firstVolume.volumeId + " (" + firstVolume.size + " GB)",
        href: firstVolume.ebsStatsLink
    }
}

function createEBSRootPartitionLink(brokerInstances: Ec2Instance[] | undefined): Link | undefined {
    if (brokerInstances === undefined) {
        return undefined;
    }

    if (brokerInstances.length === 0) {
        return undefined;
    }

    let firstInstance = brokerInstances[0];
    let volumes : Ec2Volume[] = firstInstance.volumes;
    if (volumes.length === 0) {
        return undefined;
    }
    let firstVolume = volumes[0];
    return {
        name: firstVolume.volumeId + " (" + firstVolume.size + " GB)",
        href: firstVolume.ebsStatsLink
    }
}

const BrokerInfoTable : React.FC<Props> = ({brokerInfo, brokerInstances}) => {

    let content : React.ReactElement[] = [];
    content.push(renderField("ARN", brokerInfo.arn));
    content.push(renderField("Creation Date", brokerInfo.creationDate));
    content.push(renderField("Publicly Accessible", brokerInfo.publiclyAccessible ? "true" : "false"));
    content.push(renderField("CodeDeploy", brokerInfo.codeDeploy ? brokerInfo.codeDeploy : "none"));
    content.push(renderLink("CodeDeploy Logs", brokerInfo.codeDeployLogsLink.name, brokerInfo.codeDeployLogsLink.href));
    content.push(renderLink("CFN Stack", brokerInfo.cfnStackLink.name, brokerInfo.cfnStackLink.href, brokerInfo.cfnStackLink.name));
    if (brokerInfo.efsInfo) {
        content.push(renderField("EFS data partition", brokerInfo.efsInfo));
    }
    let ebsDataPartitionLink: Link | undefined = createEBSDataPartitionLink(brokerInstances);
    if (ebsDataPartitionLink !== undefined) {
        content.push(renderLink("EBS data partition", ebsDataPartitionLink.name, ebsDataPartitionLink.href, ebsDataPartitionLink.href));
    } else {
        content.push(renderField("EBS data partition", "None"));
    }
    let ebsRootPartitionLink: Link | undefined = createEBSRootPartitionLink(brokerInstances);
    if (ebsRootPartitionLink !== undefined) {
        content.push(renderLink("EBS root partition", ebsRootPartitionLink.name, ebsRootPartitionLink.href, ebsRootPartitionLink.href));
    } else {
        content.push(renderField("EBS root partition", "None"));
    }
    content.push(renderField("Engine", brokerInfo.engine, "Pending: " + brokerInfo.pendingEngine));
    content.push(renderField("Pending Host Instance Type", brokerInfo.pendingHostInstanceType));
    content.push(renderField("EncryptionOptions", brokerInfo.encryptionOptions));
    content.push(renderLink("Cell ID", brokerInfo.cellId, brokerInfo.cellIsenLink, "Cell Link"));
    content.push(renderField("Cell Account ID", brokerInfo.cellAccountId));
    content.push(renderField("Authentication Strategy", brokerInfo.authenticationStrategy));
    content.push(renderLink("Control Plane Account ID", brokerInfo.controlPlaneAccountId, brokerInfo.controlPlaneAccountIsenLink, "Control Plane Account ID"));

    content.push(renderElement("Node Mapping", renderNodeMapping(brokerInfo.nodeMapping), brokerNodeMappingCopyText(brokerInfo.nodeMapping)));

    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>Broker Info</h2>
            </div>
            <ColumnLayout columns={3} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                    {content}
                </div>
            </ColumnLayout>
        </div>
    )
}

export default BrokerInfoTable;