import { 
    MidwayIdentityCredentialProviderSTS,  
    MidwayIdentityCredentialSTS,
  } from '@amzn/midway-identity-credential-provider';
import { AppConfig, OpsConsoleApi } from './types';
import axios from "axios";
import { Credentials, aws4Interceptor } from 'aws4-axios';
import { String } from 'aws-sdk/clients/batch';

let credentialProvider: MidwayIdentityCredentialProviderSTS;
let awsCredentials: MidwayIdentityCredentialSTS;
  
let apiClient: any;

const CLIENT_TIMEOUT_MS = 15_000;

let configJson : AppConfig | undefined = undefined;


export const fetchTemporarySigV4Credentials = async (config: AppConfig): Promise<MidwayIdentityCredentialSTS> => {
   
    if (credentialProvider === undefined) {
        credentialProvider = new MidwayIdentityCredentialProviderSTS(
            config.iamRoleArn, 
            config.clientId,
            new URL(`https://${config.redirectUri}`),
            config.partition,
            {
                maxAttempts: 3,
                region: config.region,
            }
        );
    }

    awsCredentials = await credentialProvider.getCredentials();
    return awsCredentials;
}


export const getApiClient = async (): Promise<OpsConsoleApi> => {

    if (!configJson) {
        const fullHostname = location.protocol + '//' + location.host;
        const config = await fetch(`${fullHostname}/config.json`)
        configJson = await config.json() as AppConfig;
    }
  
    const awsCredentials: MidwayIdentityCredentialSTS = await fetchTemporarySigV4Credentials(configJson);

    if (apiClient === undefined || isClientNeedsRenewal(apiClient, awsCredentials)) {

        const credentials: Credentials = {
            accessKeyId: awsCredentials.AccessKeyId!,
            secretAccessKey: awsCredentials.SecretAccessKey!,
            sessionToken: awsCredentials.SessionToken!,
        }

        const sigV4Interceptor = aws4Interceptor(
            {
                credentials: credentials,
                options: {
                    service: 'execute-api',
                    region: configJson.region
                }
            }
        );
    
        // Setup axios
        let client = axios.create({ timeout: CLIENT_TIMEOUT_MS });
        client.interceptors.request.use(sigV4Interceptor as any);

        apiClient = {
            sessionToken: awsCredentials.SessionToken,
            client: client,
            user: awsCredentials.MidwayUser
        }

    }

    return apiClient;

  };
  
  const isClientNeedsRenewal = (apiClient: OpsConsoleApi, newCredentials: MidwayIdentityCredentialSTS): boolean => {
    return apiClient.sessionToken !== newCredentials.SessionToken;
  }
