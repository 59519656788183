import './describeBroker.css';
import './logsTable.css';
import React, { useEffect, useState } from 'react';
import InfoTable from './infoTable';
import { Broker, SimIssue } from './types';
import { getSimIssues } from './api/api';
import { Flashbar } from '@amzn/awsui-components-react';

type Props = {
    broker: Broker,
    snapshotId: string,
}

const SimIssuesTable : React.FC<Props> = ({broker, snapshotId}) => {

    const [issues, setIssues] = useState<SimIssue[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setLoadingData(true);
        setError("");
        getSimIssues(broker.id, snapshotId).then(response => {
            setIssues(response.simIssues);
        }).catch(error => {
            console.error(error);
            setError("Could not load SIM Issues.");
        }).finally(() => {
            setLoadingData(false);
        })
    }, []);

    let columnDefinitions = [
        {
            id: "creationDate",
            header: "Creation Date",
            cell: (item: any) => item.creationDate
        },
        {
            id: "title",
            header: "Issue Title",
            cell: (item: any) => <a className="clickableLink" href={item.simLink} target="_blank">{item.title}</a>
        }
    ];


    return (
        <div>
            <div className="infoTable">
                {error && <Flashbar items={
                                [{
                                    "type": "error",
                                    "dismissible": false,
                                    "content": error
                                }]
                            }></Flashbar>}
                <InfoTable items={issues} header='SIM Issues' columnDefinitions={columnDefinitions} filteringPlaceholder='Search issue'
                    tablePagination={true} loadingData={loadingData} shouldDisplayExportBtn={false} />
            </div>
        </div>
    )
}

export default SimIssuesTable;
