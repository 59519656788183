import { ColumnLayout } from '@amzn/awsui-components-react';
import React from 'react';
import CopyToClipboardButton from './copyToClipboardButton';
import { BrokerConfigEntry, BrokerMaintenanceInfo } from './types';
import { renderField } from './tableUtils';

function renderConfiguration(attributeName: string, configEntry: BrokerConfigEntry) {

    if (!configEntry) {
        return (
            <div key={attributeName}>
                <div className="awsui-util-label">
                    {attributeName}
                </div>
                <div className="awsui-util-label">None</div>
            </div>
        )
    }

    let linkTitle = configEntry.configurationId + " | " + configEntry.revision;
    let isConfigInvalid = configEntry.invalid !== null ? configEntry.invalid : false;
    if (isConfigInvalid) {
        linkTitle = linkTitle + " - invalid";
    } else {
        linkTitle = linkTitle + " - ✔";
    }
    return (
        <div key={attributeName}>
            <div className="awsui-util-label">
                {attributeName}
                {configEntry &&
                    <CopyToClipboardButton text={configEntry.isenLink} textDescription={attributeName} />
                }
            </div>
            <div className="awsui-util-label">
                <a target="_blank" href={configEntry.isenLink}>{linkTitle}</a>
                <CopyToClipboardButton text={configEntry.isenLink}  />
            </div>
        </div>
    )
}

type Props = {
    brokerMaintenanceInfo: BrokerMaintenanceInfo
}

const BrokerMaintenanceInfoTable : React.FC<Props> = ({brokerMaintenanceInfo}) => {
    let content : React.ReactElement[] = [];
    content.push(renderConfiguration("Current Configuration", brokerMaintenanceInfo.currentConfiguration));
    content.push(renderConfiguration("Pending Configuration", brokerMaintenanceInfo.pendingConfiguration));
    content.push(renderField("Maintenance Window", brokerMaintenanceInfo.maintenanceWindow));
    content.push(renderField("Next Maintenance", brokerMaintenanceInfo.nextMaintenance));
    content.push(renderField("Maintenance Flag", brokerMaintenanceInfo.maintenanceFlag ? "true" : "false"));
    content.push(renderField("Auto Minor Version Upgrade", brokerMaintenanceInfo.autoMinotVersionUpgrade ? "true" : "false"));
    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>Broker Maintenance Info</h2>
            </div>
            <ColumnLayout columns={3} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                    {content}
                </div>
            </ColumnLayout>
        </div>
    )
}

export default BrokerMaintenanceInfoTable;