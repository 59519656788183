
import { Broker  } from "../../types";
import { ackRateMetric, channelClosedRateMetric, channelCreatedRateMetric, confirmRateMetric, connectionClosedRateMetric, connectionCreatedRateMetric, deliverGetRateMetric, msgDiskReadRateMetric, msgDiskWriteRateMetric, nackRateMetric, publishRateMetric, queueCreatedRateMetric, queueDeclaredRateMetric, queueDeletedRateMetric, redeliveryRateMetric } from "./rabbitBrokerMetrics";
import { widget } from "../utils";
import * as Arn from "@aws-sdk/util-arn-parser";

export function getRabbitChurnRatesMetrics(broker: Broker): any[] {
    let metrics : any[] = [];
    let region = Arn.parse(broker.brokerInfo.arn).region;

    metrics.push(widget(
        "Ack/Confirm/Publish/Redelivery Rate",
        region,
        [
            ackRateMetric(broker),
            confirmRateMetric(broker),
            publishRateMetric(broker),
            redeliveryRateMetric(broker),
            deliverGetRateMetric(broker),
            nackRateMetric(broker)
        ]
    ))

    metrics.push(widget(
        "Connection/Channel rates",
        region,
        [
            connectionCreatedRateMetric(broker),
            channelCreatedRateMetric(broker),
            connectionClosedRateMetric(broker),
            channelClosedRateMetric(broker),
        ]
    ))

    metrics.push(widget(
        "Queue rates",
        region,
        [
            queueCreatedRateMetric(broker),
            queueDeclaredRateMetric(broker),
            queueDeletedRateMetric(broker),
        ]
    ))

    metrics.push(widget(
        "Disk rates",
        region,
        [
            msgDiskReadRateMetric(broker),
            msgDiskWriteRateMetric(broker),
        ]
    ))

    return metrics;
}