import { Broker, Ec2Instance, QUEUE_STATES, QUEUE_TYPES } from "../../types";
import { widget } from "../utils";
import { CELL_HEIGHT, CELL_WIDTH, activeNodeCountAndContainerAgeWidget } from "./common";


export function getRabbitQueuesMetrics(broker: Broker, brokerInstances: Ec2Instance[]): any[] {

    let metrics = [];

    metrics.push(activeNodeCountAndContainerAgeWidget(broker, brokerInstances, 0, 0, 24, 8));
    
    metrics.push(widget(
        "Queues / Exchanges",
        broker.regionName,
        [
            queueCount(broker),
            exchangeCount(broker),
        ],
        0, // x
        8, // y
        CELL_WIDTH, // width
        CELL_HEIGHT // height
    ))

    metrics.push(widget(
        "CMQ Messages to sync",
        broker.regionName,
        [
            messagesToSync(broker)
        ],
        12, // x
        8, // y
        CELL_WIDTH, // width
        CELL_HEIGHT // height
    ))
    let yOffset = 16;
    for (let i = 0; i < QUEUE_TYPES.length; i++) {
        metrics.push(queueStateMetricsWidget(
            broker, 
            QUEUE_TYPES[i],
            (i % 2) * CELL_WIDTH,
            (i / 2) * CELL_HEIGHT + yOffset,
            CELL_WIDTH,
            CELL_HEIGHT
        ))
    }

    return metrics;
}

export function queueStateMetricsWidget(broker: Broker, queueType: string, x: number, y: number, width: number, height: number): any {
    let metrics = [];
    for (let i = 0; i < QUEUE_STATES.length; i++) {
        metrics.push(queueStateMetric(broker, QUEUE_STATES[i], queueType))
    }
    return widget(
        `${queueType} Queue States`,
        broker.regionName,
        metrics,
        x,
        y,
        width,
        height
    );
}

export function queueStateMetric(broker: Broker, queueState: string, queueType: string) {
    return [
        "AmazonMqRabbitService",
        "QueueCount",
        "BrokerId",
        broker.id,
        "QueueState",
        queueState,
        "QueueType",
        queueType,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left",
            period: 300
        }
    ]
}

export function queueCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function exchangeCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ExchangeCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function messagesToSync(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "MessagesToSync",
        "BrokerId",
        broker.id,
        {
            stat: "Minimum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left",
            period: 300
        }
    ]
}