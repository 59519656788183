import React, { useEffect, useState } from 'react';
import './describeBroker.css';
import CopyToClipboardButton from "./copyToClipboardButton";
import {ColumnLayout, Select, Spinner} from '@amzn/awsui-components-react';
import rabbitData from './rabbit-data.json';
import activeData from './active-data.json';
import { Broker, BrokerEngineType, BrokerInstance, Ec2Instance } from './types';
import { getDomainFromHost } from './utils';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[] | undefined,
}

const CommandTemplates : React.FC<Props> = ({broker, brokerInstances}) => {

    const [selectedInstance, setSelectedInstance] = useState<BrokerInstance | undefined>(undefined);

    useEffect(() => {
       if (brokerInstances === undefined) {
            setSelectedInstance(undefined);
       } else {
            setSelectedInstance(brokerInstances.length === 0 ? undefined : brokerInstances[0]);
       }
    }, [brokerInstances]);

    function generateAllCommands(broker: Broker) {

        let data = broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ ? activeData : rabbitData;

        const accountId = broker.summary.accountId;
        const cellAccountId = broker.brokerInfo.cellAccountId;
    
        const domain = getDomainFromHost();
    
        let content: any[] = [];
    
        for (let i = 0; i < data.commands.length; i++) {
            let command = data.commands[i].command
            
            command = command.replaceAll('<account-id>', accountId)
                .replaceAll('<region>', broker.region)
                .replaceAll('<region-not-airport>', broker.regionName)
                .replaceAll('<cell-account-id>', cellAccountId)
                .replaceAll('<cell>', broker.brokerInfo.cellId)
                .replaceAll('<domain>', domain)
                .replaceAll('<broker-id>', broker.id)
                .replaceAll('<instance-id>', selectedInstance !== undefined ? selectedInstance.instanceId : "")
    
            content.push(
                <div style={{display: 'flex'}}>
                    <div style={{flex: '1 1'}}>
                            <span style={{color: 'chocolate', fontSize: '16px'}}>
                                <b>{data.commands[i].title}</b>
                            </span>
                    </div>
                    <div style={{flex: '0 0'}}>
                        <CopyToClipboardButton
                            text={command}
                            textDescription={'Command Template'}
                            isLink={false}/>
                    </div>
                    <div style={{flex: '4 4'}}>
                        <span>{command}</span>
                    </div>
                </div>
            );
        }
    
        return content
    }

    let content = generateAllCommands(broker);

    if (brokerInstances === undefined) {
        return <Spinner />
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <div>
                <h4>Select Instance Id:</h4>
                <Select
                     options={brokerInstances.map(i => {
                        return {
                            label: i.instanceId,
                            id: i.instanceId
                        }
                     })}
                     selectedId={selectedInstance?.instanceId}
                     onChange={(event: any) => {
                        setSelectedInstance(brokerInstances.find(i => i.instanceId === event.detail.selectedId))
                     }}
                />
            </div>
            <ColumnLayout borders="horizontal">
                <div data-awsui-column-layout-root="true">
                    <div style={{display: 'flex'}}>
                        <div style={{flex: '1 1', paddingRight: '5px', fontWeight: 'bold'}}>Title</div>
                        <div style={{flex: '0 0', paddingRight: '5px', fontWeight: 'bold'}}>Copy</div>
                        <div style={{flex: '4 4', paddingRight: '5px', fontWeight: 'bold'}}>Command</div>
                    </div>
                    {content}
                </div>
            </ColumnLayout>
        </div>
    )

}

export default CommandTemplates;
