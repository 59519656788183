import React, { useState } from 'react';
import { BrokerInstanceMemoryBreakdown, Ec2Instance } from '../types';
import { COLOR_ERROR } from '../utils';
import MemoryBreakdownModal from './MemoryBreakdownModal';
import { Button } from '@amzn/awsui-components-react';
import MemoryBar from './MemoryBar';

type Props = {
    instance: Ec2Instance,
    instanceMemoryBreakdown: BrokerInstanceMemoryBreakdown

}
const BrokerInstanceMemoryBreakdownPanel : React.FC<Props> = ({instance, instanceMemoryBreakdown}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    if (instanceMemoryBreakdown.status === "FAILED") {
        return <span style={{color: COLOR_ERROR}}>Could not load memory breakdown</span>
    }
    return (
        <div>
            <span className='awsui-util-label'>Memory:</span>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center'}}>
                <MemoryBreakdownModal
                    open={modalOpen}
                    instance={instance}
                    instanceMemoryBreakdown={instanceMemoryBreakdown}
                    onClose={() => {
                        setModalOpen(false);
                    }
                } />
                <MemoryBar data={instanceMemoryBreakdown} />
                <Button variant="icon" icon="expand" onClick={() => {
                    setModalOpen(true);
                }} />
                {/* <a style={{cursor: 'pointer'}} onClick={(event: any) => {
                    event.preventDefault();
                
                }}>View memory breakdown</a> */}
            </div>
        </div>
    )
}

export default BrokerInstanceMemoryBreakdownPanel;