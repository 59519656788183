import { Broker, Ec2Instance, VolumeType } from "../../types";
import * as Arn from "@aws-sdk/util-arn-parser";
import { widget } from "../utils";

export function getActiveInstanceMetrics(broker: Broker, instanceId: string, qualifiedBrokerId: string, brokerInstances: Ec2Instance[]): any[] {
    let metrics : any[] = [];
    let region = Arn.parse(broker.brokerInfo.arn).region;
    let rootVolumeId = brokerInstances.find(bi => bi.instanceId === instanceId)?.volumes.find(v => v.volumeType === VolumeType.ROOT_VOLUME)?.volumeId;
    let instance: Ec2Instance | undefined = brokerInstances.find(bi => bi.instanceId === instanceId);
    let instanceTitle = instanceId;
    if (instance !== undefined) {
        let hostQualifier = instance.qualifiedBrokerId.length === 40 ? qualifiedBrokerId.substring(39) : "";
        instanceTitle = `${instance.instanceId} (${instance.brokerStatus} , $${hostQualifier})`
    }

    metrics.push(widget(
        `${instanceTitle} - Broker Process`,
        region,
        [
            agentCountMetric(broker, qualifiedBrokerId),
            brokerProcessAgeMetric(broker, qualifiedBrokerId),
            brokerProcessCountMetric(broker, qualifiedBrokerId),
            brokerKernelPanicCountMetric(broker, qualifiedBrokerId),
            brokerIsRecoveringMetric(broker, qualifiedBrokerId),
            activeInstanceCountMetric(broker, qualifiedBrokerId)
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - EC2 CPU Credits`,
        region,
        [
            cpuCreditUsageMetric(broker, instanceId),
            cpuCreditBalanceMetric(broker, instanceId),
            cpuSurplusCreditBalanceMetric(broker, instanceId),
            cpuSurplusCreditsChargedMetric(broker, instanceId)
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - CPU Utilization`,
        region,
        [
            cpuUtilizationMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - CPU Utilization`,
        region,
        [
            diskReadBytesMetric(broker, instanceId),
            diskReadOpsMetric(broker, instanceId),
            diskWriteBytesMetric(broker, instanceId),
            diskWriteOpsMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - EC2 Status Check`,
        region,
        [
            statusCheckFailedMetric(broker, instanceId),
            statusCheckFailedInstanceMetric(broker, instanceId),
            statusCheckFailedSystemMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - EC2 Network`,
        region,
        [
            networkInMetric(broker, instanceId),
            networkOutMetric(broker, instanceId),
            networkPacketsInMetric(broker, instanceId),
            networkPacketsOutMetric(broker, instanceId),
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - EBS Latency`,
        region,
        [
            volumeTotalReadTimeMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeTotalWriteTimeMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeQueueLengthMetric(broker, rootVolumeId !== undefined ? rootVolumeId : "")
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - EBS Throughput`,
        region,
        [
            volumeReadBytesMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeWriteBytesMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeReadOpsMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            volumeWriteOpsMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
        ]
    ))

    metrics.push(widget(
        `${instanceTitle} - EBS/EFS Burst Balance`,
        region,
        [
            burstBalanceMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
            burstCreditBalanceMetric(broker, rootVolumeId !== undefined ? rootVolumeId : ""),
        ]
    ))

    return metrics;
}

export function agentCountMetric(broker: Broker, qualifiedBrokerId: string) {
    return [
        "AmazonMqService",
        "AgentCount",
        "QualifiedBrokerId",
        qualifiedBrokerId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function brokerProcessAgeMetric(broker: Broker, qualifiedBrokerId: string) {
    return [
        "AmazonMqService",
        "BrokerProcessAge",
        "QualifiedBrokerId",
        qualifiedBrokerId,
        {
            stat: "Minimum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function brokerProcessCountMetric(broker: Broker, qualifiedBrokerId: string) {
    return [
        "AmazonMqService",
        "BrokerProcessCount",
        "QualifiedBrokerId",
        qualifiedBrokerId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function brokerKernelPanicCountMetric(broker: Broker, qualifiedBrokerId: string) {
    return [
        "AmazonMqService",
        "KernelPanicCount",
        "QualifiedBrokerId",
        qualifiedBrokerId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function brokerIsRecoveringMetric(broker: Broker, qualifiedBrokerId: string) {
    return [
        "AmazonMqService",
        "IsRecovering",
        "QualifiedBrokerId",
        qualifiedBrokerId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function activeInstanceCountMetric(broker: Broker, qualifiedBrokerId: string) {
    return [
        "AmazonMqService",
        "ActiveInstanceCount",
        "QualifiedBrokerId",
        qualifiedBrokerId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function cpuCreditUsageMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "CPUCreditUsage",
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function cpuCreditBalanceMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "CPUCreditBalance",
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}


export function cpuSurplusCreditBalanceMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "CPUSurplusCreditBalance",
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}


export function cpuSurplusCreditsChargedMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "CPUSurplusCreditsCharged",
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function cpuUtilizationMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "CPUUtilization",
        "InstanceId",
        instanceId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function diskReadBytesMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "DiskReadBytes",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function diskWriteBytesMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "DiskWriteBytes",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function diskReadOpsMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "DiskReadOps",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function diskWriteOpsMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "DiskWriteOps",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function statusCheckFailedMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "StatusCheckFailed",
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function statusCheckFailedInstanceMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "StatusCheckFailed_Instance",
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function statusCheckFailedSystemMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "StatusCheckFailed_System",
        "InstanceId",
        instanceId,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function networkInMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkIn",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function networkOutMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkOut",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function networkPacketsInMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkPacketsIn",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}


export function networkPacketsOutMetric(broker: Broker, instanceId: string) {
    return [
        "AWS/EC2",
        "NetworkPacketsOut",
        "InstanceId",
        instanceId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function volumeTotalReadTimeMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeTotalReadTime",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeTotalWriteTimeMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeTotalWriteTime",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeQueueLengthMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeQueueLength",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}


export function volumeReadBytesMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeReadBytes",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeWriteBytesMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeWriteBytes",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function volumeReadOpsMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeReadOps",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function volumeWriteOpsMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "VolumeWriteOps",
        "VolumeId",
        volumeId,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function burstBalanceMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "BurstBalance",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function burstCreditBalanceMetric(broker: Broker, volumeId: string) {
    return [
        "AWS/EBS",
        "BurstCreditBalance",
        "VolumeId",
        volumeId,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}