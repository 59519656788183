import './describeBroker.css';
import './logsTable.css';
import React, { useState } from 'react';
import LogDetailsTable from './logDetailsTable';
import InfoTable from './infoTable';
import { Broker, LogStream } from './types';
import { getLogs } from './api/api';
import { Modal, Button } from '@amzn/awsui-components-react';

type Props = {
    broker: Broker,
    logStreamNames: LogStream[],
    loadingStreamNames: boolean
}

const LogsTable : React.FC<Props> = ({broker, logStreamNames, loadingStreamNames}) =>  {

    const [logs, setLogs] = useState<any[]>([]);
    const [loadingLogData, setLoadingLogData] = useState<boolean>(false);
    const [selectedLogStream, setSelectedLogStream] = useState<LogStream | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    function loadLogs(logStream: LogStream) {
        setLoadingLogData(true);
        setSelectedLogStream(logStream);
        setModalOpen(true);
        getLogs(broker.id, broker.snapshotId, logStream.id).then(response => {
            setLogs(response.logs);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingLogData(false);
        })
    }

    let columnDefinitions = [
        {
            id: "lastEventTimestamp",
            header: "Last Event Timestamp",
            cell: (item: any) => <a className="clickableLink" onClick={() => loadLogs(item)}>{item.latestTimestamp}</a>,
            field: "latestTimestamp"
        },
        {
            id: "logGroup",
            header: "Log Group",
            cell: (item: any) => <a className="clickableLink" onClick={() => loadLogs(item)}>{item.logGroupName}</a>,
            field: "logGroupName"
        },
        {
            id: "logStream",
            header: "Log Stream",
            cell: (item: any) => <a className="clickableLink" onClick={() => loadLogs(item)}>{item.title}</a>,
            field: "title"
        },
        {
            id: "instanceId",
            header: "Instance ID",
            cell: (item: any) => <a className="clickableLink" onClick={() => loadLogs(item)}>{item.instanceId}</a>,
            field: "instanceId"
        },
        {
            id: "isengardLink",
            header: "Cloud Watch Logs",
            cell: (item: any) => <a className="clickableLink" href={item.isenLink} target="_blank">Isengard Link</a>,
        }
    ];

    let sortableColumns = [
        {
            id: "logGroup",
            field: "logGroupName"
        },
        {
            id: "instanceId",
            field: "instanceId"
        },
        {
            id: "logStream",
            field: "title"
        },
        {
            id: "lastEventTimestamp",
            field: "latestTimestampEpochMillis"
        }
    ];

    let logDetailsColumnDefinitions = [
        {
            id: "log",
            header: "Logs"
        }
    ]

    return (
        <div>
            <div className="infoTable">
                <InfoTable 
                    items={logStreamNames}
                    header='Log Streams' 
                    columnDefinitions={columnDefinitions} 
                    filteringPlaceholder='Search log stream' 
                    loadingData={loadingStreamNames}
                    sortableColumns={sortableColumns} 
                    sortingColumn='lastEventTimestamp' 
                    sortingDescending={true} 
                    shouldDisplayExportBtn={false}
                />
            </div>
            {modalOpen &&
                 <Modal
                    size="large"
                    visible={modalOpen}
                    className={"fullScreenModal"}
                    header={selectedLogStream?.logStreamName}
                    content={
                        <LogDetailsTable
                            logs={logs}
                            logTableTitle={"Latest Logs"}
                            loadingData={loadingLogData}
                        />                        
                    }
                    onDismiss={() => {
                        setModalOpen(false);
                    }}
                    footer={
                        <span className="awsui-util-f-r">
                            <Button variant="primary" text="Ok" onClick={() => {
                                setModalOpen(false);
                            }}/>
                        </span>
                    }>
                </Modal>
            }
        </div>
    );

}

export default LogsTable;
