import '@amzn/awsui-components-react/index.css';
import React from 'react';
import { ColumnLayout, Spinner } from '@amzn/awsui-components-react';
import PlotMetric from './plotMetric';

type Props = {
    destinationType: string,
    destinationName: string,
    loadingData: boolean,
    enqueueCount: any,
    dequeueCount: any,
    consumerCount: any,
    producerCount: any
}

const CustomerMetrics : React.FC<Props> = ({destinationType, destinationName, loadingData, enqueueCount, dequeueCount, consumerCount, producerCount}) => {

    return (
        <div style={{marginTop:"50px"}}>
            <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2> {destinationType} Name : {destinationName} </h2>
            </div>
            {loadingData === true ? (
                <Spinner></Spinner>
            ) : (
                <ColumnLayout columns={2} variant="text-grid">
                    <div data-awsui-column-layout-root="true">
                        <PlotMetric graphName="Enqueue Count" metricsData={enqueueCount} />
                        <PlotMetric graphName="Dequeue Count" metricsData={dequeueCount} />
                        <PlotMetric graphName="Consumer Count" metricsData={consumerCount} />
                        <PlotMetric graphName="Producer Count" metricsData={producerCount} />
                    </div>
                    </ColumnLayout>
                )}
            </div>
        </div>
    );

}

export default CustomerMetrics;
