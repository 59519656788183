import './describeBroker.css';
import React, { useEffect, useState } from 'react';
import InfoTable from './infoTable';
import { Broker, EventHistoryEntry } from './types';
import { getEvents } from './api/api';

type Props = {
    broker: Broker,
    snapshotId: string,
}

const EventHistoryTable : React.FC<Props> = ({broker, snapshotId}) => {

    const [events, setEvents] = useState<EventHistoryEntry[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    useEffect(() => {
        setLoadingData(true);
        getEvents(broker.id, snapshotId).then(response => {
            setEvents(response.events);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingData(false);
        })
    }, []);

  
    let columnDefinitions = [
        {
            id: "brokerState",
            header: "Broker State",
            cell: (item: any) => item.brokerState
        },
        {
            id: "eventType",
            header: "Event Type",
            cell: (item: any) => item.eventType
        },
        {
            id: "message",
            header: "Message",
            cell: (item: any) => item.message
        },
        {
            id: "eventData",
            header: "Event Data",
            cell: (item: any) => item.eventData
        },
        {
            id: "timeStamp",
            header: "timestamp",
            cell: (item: any) => item.timeStamp
        }
    ]

    let sortableColumns = [
        {
            id: "eventName",
            field: "eventName"
        },
        {
            id: "message",
            field: "message"
        },
        {
            id: "timeStamp",
            field: "timeStamp"
        }
    ]

    return (
        <div className="infoTable">
            <InfoTable
                items={events}
                header='Event History'
                columnDefinitions={columnDefinitions}
                loadingData={loadingData}
                filteringPlaceholder='Search event'
                sortableColumns={sortableColumns}
                shouldDisplayExportBtn={false}
            />
        </div>
    );

}

export default EventHistoryTable;
