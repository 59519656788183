import React from 'react';
import { Button, Popover, Icon } from '@amzn/awsui-components-react';

type Props = {
    textDescription?: string;
    text: string;
    isLink?: boolean;
    disabled?: boolean;
}

function copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text)
        .catch((e) => { console.error(e); alert('Copy error!') })
}

const CopyToClipboardButton : React.FC<Props> = ({textDescription, text, isLink = false, disabled = false}) => {

    const truncateString = (str: string | undefined) =>  str === undefined || str === null ? "" : (str.length > 7) ? str.substr(0, 10-1) + '...' : str;
    return (
        <Popover
            size="small"
            position="bottom"
            triggerType="custom"
            dismissButton={false}
            content={
                <span className="awsui-util-status-positive">
                    <Icon variant="success" name="status-positive"/>
                    &nbsp;
                    {textDescription || truncateString(text)} copied!
                </span>
            }
        >
            <Button disabled={disabled} variant="icon" icon={isLink ? 'share' : 'copy'}
                    onClick={() => copyToClipboard(text)} />
        </Popover>
    )

}

export default CopyToClipboardButton;