import './describeBroker.css';
import './logsTable.css';
import './infoTable.css';
import React from 'react';
import {Button, Table, TableFiltering, TablePagination, TableSorting} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";

class InfoTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filteringText: '',
            placeholder: this.props.filteringPlaceholder,
            sortableColumns: this.props.sortableColumns,
            columnDefinitions: this.props.columnDefinitions
        };
    }

    getExportData(data) {
        var newData = [];
        for (var i = 0; i < data.length; i++) { 
            var cause = data[i].cause;
            var message = data[i].message;
            if(cause !== undefined) {
                cause = cause.replace(new RegExp(",", 'g'), " ");
                var copy = JSON.parse(JSON.stringify(data[i]));
                copy.cause = cause;
                newData.push(copy);
            } else if(message !== undefined) {
                message = message.replace(new RegExp(",", 'g'), " ");
                message = message.replace(new RegExp("\n", 'g'), " ");
                var copy = JSON.parse(JSON.stringify(data[i]));
                copy.message = message;
                newData.push(copy);
            } else {
                newData.push(data[i]);
            }
        }
        return newData;
    }

    clearFilter() {
        this.setState({
            filteringText: ''
        });
    }

    changeTable(currentInfoTableConst, columnDefinitions) {
        this.setState({
            header: currentInfoTableConst.header,
            placeholder: currentInfoTableConst.placeholder,
            sortableColumns: currentInfoTableConst.sortableColumns,
            columnDefinitions: columnDefinitions
        });
    }

    render() {
        var sortingColumn = this.props.sortingColumn;
        var sortingDescending = this.props.sortingDescending;
        var columnDefinitions = this.props.columnDefinitions;

        if (!this.props.sortingColumn) {
            sortingColumn = '';
        }

        if (!this.props.sortingDescending) {
            sortingDescending = false;
        }

        return (
            <div className="infoTableDiv">
                <div className={this.props.tableClassName || 'infoTable'}>
                    <Table
                        resizableColumns
                        columnDefinitions={columnDefinitions}
                        items={this.props.loadingData ? [] : this.props.items}
                        empty={
                            <div className="awsui-util-t-c">
                                <div className="awsui-util-pt-xs awsui-util-mb-xs">
                                    <b>
                                        No results found
                                    </b>
                                </div>
                            </div>
                        }
                        loading={this.props.loadingData}
                        noMatch={
                            <div className='awsui-util-t-c'>
                                <div>
                                    <b>No matches</b>
                                </div>
                                <p className='awsui-util-mb-xs'>No results match your query</p>
                                <div className='awsui-util-mb-xl'>
                                    <Button onClick={this.clearFilter.bind(this)} text='Clear filter'/>
                                </div>
                            </div>
                        }
                        header={
                            <h2> {this.props.header} ({this.props.items.length})</h2>
                        }
                    >
                        <TableFiltering filteringPlaceholder={this.state.placeholder}
                                        filteringText={this.state.filteringText}/>
                        {this.props.tablePagination &&
                        <TablePagination pageSize={this.props.pageSize || 25}/>
                        }


                        <TableSorting sortableColumns={this.state.sortableColumns} sortingColumn={sortingColumn}
                                    sortingDescending={sortingDescending}></TableSorting>
                    </Table>
                </div>
                <div className={this.props.shouldDisplayExportBtn? "exportBtn" : "hideExportBtn"}>
                    <CSVLink data={this.getExportData(this.props.items)} filename={this.props.tableName}>
                        <Button text='Export to CSV'/>
                    </CSVLink>
                </div>
            </div>
        );
    }
}

InfoTable.propTypes = {
    items: PropTypes.array,
    header: PropTypes.string,
    columnDefinitions: PropTypes.array,
    loadingData: PropTypes.bool,
    tablePagination: PropTypes.bool,
    filteringPlaceholder: PropTypes.string,
    sortableColumn: PropTypes.array,
    tableClassName: PropTypes.string,
    pageSize: PropTypes.number
};

InfoTable.defaultProps = {
    loadingData: false,
    tablePagination: false,
    sortableColumns: []
}

export default InfoTable;
