'use strict';

import React from 'react';
import { Dashboard } from '@amzn/cloudwatchdashboards-inside'

class CloudWatchDashboard extends React.Component {
    dashboardContainer;
    dashboard;

    constructor(props) {
        super(props);
        this.displayDashboard = this.displayDashboard.bind(this);
    }

    componentDidMount() {
        this.dashboard = new Dashboard(
            this.dashboardContainer,
            this.props.title,
            this.props.region,
            {
                heightUnit: 40,
                widgetMarginX: 10,
                widgetMarginY: 10,
                staticWidgets: true,
                displayMode: 'static',
                style: 'polaris',
                internal: {accountId: this.props.serviceAccountId}
            });

        this.dashboard.on('ready', this.displayDashboard);
    }

    displayDashboard() {
        this.dashboard.update(this.props.model, false);
    }

    render() {
        return <div ref={(div) => this.dashboardContainer = div}/>;
    }
}

export default CloudWatchDashboard;