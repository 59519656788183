import React from 'react';
import '@amzn/awsui-components-react/index.css';
import {
    Flashbar
} from '@amzn/awsui-components-react';

const PageNotFound : React.FC = () => {
    return (
        <div className='awsui'>
            <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": "Page not found"
                }]
            }></Flashbar>
        </div>
    )
}

export default PageNotFound;