import './describeBroker.css';
import React, { useEffect, useState } from 'react';
import InfoTable from './infoTable';
import { Broker, Workflow } from './types';
import { getRecentWorkflows } from './api/api';
import { Flashbar } from '@amzn/awsui-components-react';

type Props = {
    broker: Broker,
    snapshotId: string,
}  

const WorkflowsTable : React.FC<Props> = ({broker, snapshotId}) =>  {


    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setLoadingData(true);
        setError("");
        getRecentWorkflows(broker.id, snapshotId).then(response => {
            setWorkflows(response.workflows);
        }).catch(error => {
            setError("Could not load workflows.");
            console.error(error);
        }).finally(() => {
            setLoadingData(false)
        })
    }, []);

    function getCodeDeployments(codeDeploymentLinks: any[]) {
        return codeDeploymentLinks.map(function(deployment: any) {
            return <div key={deployment["id"]}><a href={deployment["link"]} target="_blank">{deployment["id"]}</a><br></br></div>;
        })
    }

    let columnDefinitions = [
        {
            id: "workflowName",
            header: "Workflow",
            cell: (item: any) => item.workflowName
        },
        {
            id: "maintenance",
            header: "Maintenance",
            cell: (item: any) => item.executionArn.includes("Workflow:mw-") ? "true" : "false"
        },
        {
            id: "name",
            header: "StepFunctions",
            cell: (item: any) => <a href={item.link} target="_blank">StepFunctions Link</a>
        },
        {
            id: "status",
            header: "Status",
            cell: (item: any) => item.status
        },
        {
            id: "codeDeploy",
            header: "Code Deployments",
            cell: (item: any) => Object.keys(item.codeDeploymentLinks).length !== 0 ? getCodeDeployments(item.codeDeploymentLinks) : "none"

        },
        {
            id: "cfnStack",
            header: "CloudFormation Stack",
            cell: (item: any) => <a href={item.cfnStackLink} target="_blank">CloudFormation Link</a>

        },
        {
            id: "startTime",
            header: "Start Time",
            cell: (item: any) => item.startTime
        },
        {
            id: "endTime",
            header: "End Time",
            cell: (item: any) => item.endTime
        }
    ]

    let sortableColumns = [
        {
            id: "workflowName",
            field: "workflowName"
        },
        {
            id: "startTime",
            field: "startTime"
        },
        {
            id: "endTime",
            field: "endTime"
        }
    ]

    return (
        <div className="infoTable">
             {error && <Flashbar items={
                                [{
                                    "type": "error",
                                    "dismissible": false,
                                    "content": error
                                }]
                            }></Flashbar>}
            <InfoTable
                items={workflows}
                header='Recent Workflows'
                columnDefinitions={columnDefinitions}
                loadingData={loadingData}
                filteringPlaceholder='Search workflow'
                sortableColumns={sortableColumns}
                shouldDisplayExportBtn={false}
            />
        </div>
    )

}

export default WorkflowsTable;
