import React from 'react';
import { BrokerInstanceMemoryBreakdown } from '../types';
import './memory-bar.css';
import { SECTIONS } from './const';

type Props = {
    data: BrokerInstanceMemoryBreakdown,
}

const MemoryBar : React.FC<Props> = ({data}) => {

    let pseudoTotal = 0;

    let breakdown: any = data.memoryBreakdown;
    for (let section in SECTIONS) {
        pseudoTotal += breakdown[section] ?  breakdown[section].bytes : 0;
    }

    if (pseudoTotal === 0) {
        return <>No data</>
    }

    return (
        <div className='memory-bar' style={{alignItems: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%'}}>
            {Object.keys(SECTIONS).map((sectionName: string) => {
                let memoryUsedBytes = breakdown[sectionName] ? breakdown[sectionName].bytes : 0;
                if (memoryUsedBytes > 0) {
                    let sectionGroupName = SECTIONS[sectionName][0];
                    let memoryUsedInGbFormatted = (memoryUsedBytes / Math.pow(10, 9)).toFixed(5) + ' gb';
                    let sectionWidth = Math.round(100.0 * memoryUsedBytes / pseudoTotal);
                    return (
                        <div className={`memory-section memory_${sectionGroupName}`}
                            style={{width: `${sectionWidth}%`}}
                            title={`${sectionName} ${memoryUsedInGbFormatted}`}>
                        </div>
                    )
                } else {
                    return <></>
                }
            })}
        </div>
        
    )
}

export default MemoryBar;