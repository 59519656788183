import React from 'react';
import {
    Button,
    ColumnLayout,
    Form,
    FormField,
    FormSection,
    Input,
    Textarea,
} from '@amzn/awsui-components-react';
import { Broker } from './types';

type Props = {
    broker: Broker,
    snapshotId: string,
}

const ConfigOverride : React.FC<Props> = () => {

    return (<div>
        <div>
            <Form
                header="RabbitMQ Broker Configuration Override"
                actions={<div>
                    <Button
                        disabled={true}
                        variant="primary"
                    >
                        Submit
                    </Button>
                </div>}
            >
                <div>
                    <div><h3>Current configuration overrides</h3></div>
                    <div>
                        <Textarea
                            disabled={true}
                            readonly={true}
                            rows={5}
                        />
                    </div>
                </div>
                <FormSection header="Update configuration overrides">
                    <ColumnLayout>
                        <div data-awsui-column-layout-root="true">
                            <FormField label="Consumer Timeout (min: 1800000, max: 2073600000)">
                                <Input
                                    disabled={true}
                                    type="number"
                                    inputmode="numeric"
                                    autocomplete={false}
                                    // min="1800000" // TODO feature in progress
                                    // max="2073600000"
                                />
                            </FormField>
                        </div>
                    </ColumnLayout>
                </FormSection>
            </Form>
        </div>
    </div>
    );
}

export default ConfigOverride;
