import React from 'react';
import { BrokerEngineType, BrokerSummary, DRMode } from './types';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { renderElement, renderField, renderLink } from './tableUtils';
import { COLOR_ERROR, COLOR_INFO, COLOR_SUCCESS } from './utils';

type Props = {
    brokerSummary: BrokerSummary,
}

function renderBrokerName(brokerSummary: BrokerSummary): React.ReactElement {
    let stateStyle : React.CSSProperties = {};
    if (brokerSummary.brokerState === 'AVAILABLE') {
        stateStyle = { color: COLOR_SUCCESS };
    } else if (brokerSummary.brokerState.indexOf('FAILED') >= 0) {
        stateStyle = { color: COLOR_ERROR };
    } else {
        // anything else - info
        stateStyle = { color: COLOR_INFO };
    }
    return (
        <span>{brokerSummary.name} <span style={stateStyle}>({brokerSummary.brokerState})</span></span>
    )
}

function renderCRDRSection(brokerSummary: BrokerSummary) : React.ReactNode {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <span>Running: {brokerSummary.drMode}</span>
            <span>Pending: {brokerSummary.pendingDrMode}</span>
            {brokerSummary.replicationRole &&
                <span>Replication Role: {brokerSummary.replicationRole}</span>
            }
            {brokerSummary.drCounterPart &&
                <a href={`/broker/${brokerSummary.drCounterPart.brokerId}&region=${brokerSummary.drCounterPart.region}`}>Counterpart</a>
            }
        </div>
    )
}

const BrokerSummaryTable : React.FC<Props> = ({brokerSummary}) => {

    let content : React.ReactElement[] = [];
    content.push(renderElement("Name", renderBrokerName(brokerSummary), brokerSummary.name));
    content.push(renderLink("Account", brokerSummary.accountId, brokerSummary.accountLink, "Account Link"));
    content.push(renderField("Host Instance Type", brokerSummary.hostInstanceType));
    content.push(renderField("Broker Engine Type", brokerSummary.brokerEngineType));
    content.push(renderField("Deployment Mode", brokerSummary.deploymentMode));
    if (brokerSummary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        content.push(renderField("Broker Tags", brokerSummary.brokerTags.length > 0 ? brokerSummary.brokerTags.join(", ") : "None"));
    }
    if (brokerSummary.brokerEngineType === BrokerEngineType.ACTIVEMQ) {
        content.push(renderField("Broker Instance Internal Tags",
            brokerSummary.brokerInstanceInternalTags.length > 0 ? brokerSummary.brokerInstanceInternalTags.join(", ") : "None"));
    }
    content.push(renderField("Quarantine States", brokerSummary.quarantineStates));

    if (brokerSummary.drMode !== DRMode.NONE) {
        content.push(renderElement("DR Mode", renderCRDRSection(brokerSummary), ""))
    }

    return (
        <div className="awsui-util-container">
            <div className="awsui-util-container-header">
                <h2>Broker Summary</h2>
            </div>
            <ColumnLayout columns={4} variant="text-grid">
                <div data-awsui-column-layout-root="true">
                    {content}
                </div>
            </ColumnLayout>
        </div>
    )
}

export default BrokerSummaryTable;