import { Dashboard } from '@amzn/cloudwatchdashboards-inside';
import React, { useEffect, useRef, useState } from 'react';
import { CloudWatchWidget } from './types';
import { Spinner } from '@amzn/awsui-components-react';

type Props = {
    region: string,
    widgetsPerRow?: number,
    serviceAccountId: string,
    widgets: CloudWatchWidget[],
    layoutType: 'manual' | 'automatic',
    periodOverride?: 'inherit' | 'auto'
}

export const dashboardConfig = (region: string, serviceAccountId: string) => {
    return {
      displayMode: 'static',
      widgetMarginX: 10,
      widgetMarginY: 10,
      staticWidgets: true,
      style: 'polaris',
      internal: {
            accountId: serviceAccountId,
      },
      region,
    };
};

const CloudWatchDashboard : React.FC<Props> = ({region, layoutType, widgetsPerRow, serviceAccountId, widgets, periodOverride = 'auto'}) => {
    const dashboard = useRef<Dashboard | undefined>(undefined);
    const dashboardContainer = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dashboard.current = new Dashboard(
            dashboardContainer.current,
            'Dashboard',
            region,
            dashboardConfig(region, serviceAccountId)
        );
        dashboard.current.on('ready', () => displayDashboard());

    }, []);

    useEffect(() => {
        if (dashboard.current === undefined) {
            return;
        }
        dashboard.current.update(
            { widgets: widgets, start: "-P3D", periodOverride: periodOverride },
            layoutType === 'automatic' ?
            {
                widgetsPerRow: widgetsPerRow,
                widgetHeightInUnits: 8,
            } : undefined
        );
    }, [widgets]);

    function displayDashboard() {
        if (dashboard.current === undefined) {
            return;
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            {
                loading &&
                <div className="spinner">
                    <Spinner />
                </div>
            }
            <div ref={dashboardContainer} />
        </React.Fragment>
    );
}

export default CloudWatchDashboard;