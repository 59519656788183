import React from 'react';
import { utcFormat } from 'd3-time-format';
import { ColumnLayout } from '@amzn/awsui-components-react';
import CopyToClipboardButton from "./copyToClipboardButton";
import { InstanceHistory } from './types';

type Props = {
    instanceHistory: InstanceHistory[]
}

const InstanceHistoryTable : React.FC<Props> = ({instanceHistory}) => {

    return (
        <div className="awsui-util-container">
            <div className={`awsui-util-container-header`}>
                {<h2>Instance History</h2>}
            </div>
            <div className="awsui-util-label">Max 10 most recent instances are displayed</div>
            <div>
                <ColumnLayout columns={3} variant="text-grid">
                    <div data-awsui-column-layout-root="true">
                        {instanceHistory.map((history, idx) => {
                            return (
                                <div key={`instance-${idx}`}>
                                    <div className='awsui-util-label'>
                                    <span>{history.instanceId}</span>
                                    <span><CopyToClipboardButton text={history.instanceId}/></span>
                                    </div>
                                    <span className="awsui-util-label">Created: {utcFormat('%B %d, %Y %H:%M')(new Date(history.createTime))}</span>
                                </div>
                            )
                        })}
                    </div>
                </ColumnLayout>
            </div>
        </div>
    );

}

export default InstanceHistoryTable;
