import React, { useEffect, useState } from 'react';
import InfoTable from './infoTable';
import {
    Modal,
    Button
} from '@amzn/awsui-components-react';
import { Broker, HOTWExecution } from './types';
import { getHOTWExecutions } from './api/api';

type Props = {
    broker: Broker,
    snapshotId: string,
}

const HOTWExecutionsTable : React.FC<Props> = ({broker, snapshotId}) => {

    const [executions, setExecutions] = useState<HOTWExecution[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
    const [modalHeader, setModalHeader] = useState<string>("");
    const [modalContent, setModalContent] = useState<string>("");

    useEffect(() => {
        setLoadingData(true);
        getHOTWExecutions(broker.id, snapshotId).then(response => {
            setExecutions(response.executions);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingData(false);
        })
    }, []);

    function closeModal() {
        setModalIsVisible(false);
        setModalHeader("");
        setModalContent("");
    }

    function openModal(header: string, content: string) {
        setModalHeader(header);
        setModalIsVisible(true);
        setModalContent(content);
    }

    function getDetailsCell(item: any) {
        if (item.details === undefined || item.details.length === 0) {
            return "No HOTW Execution details found";
        }

        let executionDetailsModalContents = joinStringInList(item.details);
        let executionDetailsPreview;

        if (item.details.length > 2) {
            executionDetailsPreview = "Click to show the full execution details content...";
        } else {
            executionDetailsPreview = joinStringInList(item.details);
        }

        return <Button variant="link" onClick={() => {
            openModal("Details", executionDetailsModalContents);
        }}><p style={{textAlign: 'left'}}>{executionDetailsPreview}</p></Button>;
    }

    function joinStringInList(list: string[]) {
        let joinedString = "";
        list.map((value) => joinedString += value + "\n");
        return joinedString;
    }

    let columnDefinitions = [
        {
            id: "timeStamp",
            header: "Timestamp",
            cell: (item: any) => item.timestamp
        },
        {
            id: "sopName",
            header: "SOP Name",
            cell: (item: any)  => item.sopName
        },
        {
            id: "executionStatus",
            header: "Status",
            cell: (item: any)  => item.executionStatus
        },
        {
            id: "actionTaken",
            header: "Action Taken",
            cell: (item: any)  => '' + item.actionTaken
        },
        {
            id: "details",
            header: "Details",
            cell: (item: any) => getDetailsCell(item)
        }
    ];

    let sortableColumns = [
        {
            id: "timeStamp",
            field: "timestamp"
        }
    ];

    return (
        <div>
            <div className="infoTable">
                <InfoTable items={executions} header='HOTW Executions' columnDefinitions={columnDefinitions} filteringPlaceholder='Search HOTW Executions'
                    tablePagination={true} loadingData={loadingData} sortableColumns={sortableColumns} sortingColumn='timeStamp' sortingDescending={true}/>
            </div>
            <Modal
                size="max"
                visible={modalIsVisible}
                header={modalHeader}
                onDismiss={() => {
                    closeModal();
                }}
                footer={
                    <span className="awsui-util-f-r">
                        <Button variant="primary" text="Ok" onClick={() => {
                            closeModal();
                        }}/>
                    </span>
                }>
                <span>{modalContent}</span>
            </Modal>
        </div>
    );
}

export default HOTWExecutionsTable;
