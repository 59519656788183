import '@amzn/awsui-components-react/index.css';
import React, { useEffect, useState } from 'react';
import {
    AppLayout,
    Button,
    Select,
    Input,
    Spinner
} from '@amzn/awsui-components-react';
import NavBar from '../navBar';
import './mainPage.css';
import { Config, Region, Domain, ALL_DOMAINS } from '../types';
import { buildHostForDomainAndRegion, generateRegionsOptions, getDomainFromHost, getRegionFromHost, getRegions } from '../utils';
import { useNavigate } from 'react-router';
import { getApiClient } from '../api/client';

function getDomains(username: string | undefined): Domain[] {
    if (username === undefined) {
        return ALL_DOMAINS;
    }
    let domains = [...ALL_DOMAINS];
    domains.push({
        "label": `dev-${username}`,
        "id": `dev-${username}`,
    });
    return domains;
}

const MainPage : React.FC = () => {

    const defaultRegion = getRegionFromHost();

    const [config, setConfig] = useState<Config | undefined>(undefined);
    const [regions, setRegions] = useState<Region[] | undefined>(undefined);
    const [selectedBrokerDomain, setSelectedBrokerDomain] = useState<Domain | undefined>(getDomains(undefined).find(d => d.id === getDomainFromHost()));
    const [brokerId, setBrokerId] = useState<string>("");
    const [selectedBrokerRegion, setSelectedBrokerRegion] = useState<Region | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {

        getApiClient().then(api => {
            setConfig({
                username: api.user
            });
            setSelectedBrokerDomain(getDomains(api.user).find(d => d.id === getDomainFromHost()));
        }).catch(error => {
            console.error(error);
        })
    
        getRegions().then(regions => {
            setRegions(regions);
            if (selectedBrokerRegion === undefined) {
                setSelectedBrokerRegion(regions.find(r => r.regionCode.toLowerCase() === defaultRegion.toLowerCase()));
            }
        }).catch(error => {
            console.error(error);
        })

    }, [])


    if (config === undefined || regions === undefined 
        || selectedBrokerRegion === undefined
        || selectedBrokerDomain === undefined
        ) {
        return (
            <div className='awsui'>
                <Spinner size='large' />
            </div>
        )
    }

    const domains = getDomains(config.username);

    function searchBroker(brokerId: string, selectedDomain: string, selectedRegionNotAirport: string) {
        let newHost = buildHostForDomainAndRegion(selectedDomain, selectedRegionNotAirport);
        if (window.location.host !== newHost) {
            window.location.href = `https://${newHost}/broker/${brokerId}`
        } else {
            navigate(`/broker/${brokerId}`);
        }
    }

    return (
        <div className='awsui'>
            <AppLayout
                content={
                    <div>
                        <NavBar shouldHideSearchBar={true} defaultDomain={'prod'} defaultRegionOrAirportCode={'pdx'} username={config.username}/>  
                        <div className="mainPage">
                            <div className="searchBar" id="mainPage-describeBroker">
                                <div className="searchBar-item">
                                    <Select options={domains} selectedOption={selectedBrokerDomain} onChange={(event: any) => {
                                        setSelectedBrokerDomain(domains.find(d => d.id === event.detail.selectedId));
                                    }}/>
                                </div>
                                <div className="searchBar-item region">
                                    <Select options={generateRegionsOptions(regions)} selectedId={selectedBrokerRegion.airportCode.toLowerCase()} onChange={(event: any) => {
                                        setSelectedBrokerRegion(regions.find(r => r.airportCode.toLowerCase() === event.detail.selectedId));
                                    }}/>
                                </div>
                                <div className="searchBar-item">
                                    <Input type="search" value={brokerId} placeholder='Search broker ID/ARN' onKeydown={(event) => {
                                        if (event.detail.key === 'Enter') {
                                            searchBroker(brokerId, selectedBrokerDomain.id, selectedBrokerRegion.regionCode);
                                        }
                                    }} onInput={(event: any) => {
                                        setBrokerId(event.detail.value);
                                    }} autocomplete={false}/>
                                </div>
                                <div className="searchBar-item">
                                    <Button
                                        variant="primary"
                                        href={`https://${buildHostForDomainAndRegion(selectedBrokerDomain.id, selectedBrokerRegion.regionCode)}/broker/${brokerId}`}
                                    >
                                        Describe Broker
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                toolsHide={true}
                navigationHide={true}
            />
        </div>
    )
}

export default MainPage;
