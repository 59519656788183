import React, { useEffect, useState } from 'react';

import { BrokerEnvironment } from './types';
import JSONPretty from 'react-json-pretty';

import {
    Flashbar,
    Spinner,
} from '@amzn/awsui-components-react';
import { Broker } from './types';
import { getRabbitBrokerEnvironment } from './api/api';
import CopyToClipboardButton from './copyToClipboardButton';

type Props = {
    broker: Broker,
    snapshotId: string,
    serviceAccountId: string
}

const BrokerEnvironmentTab : React.FC<Props> = ({broker, snapshotId}) => {

    const [brokerEnvironment, setBrokerEnvironment] = useState<BrokerEnvironment | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitBrokerEnvironment(broker.id, snapshotId).then(response => {
            setBrokerEnvironment(response);
        }).catch(error => {
            console.error(error);
            setError("Could not load broker environment");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id])

    return (<div>
         {error && <Flashbar items={
            [{
                "type": "error",
                "dismissible": false,
                "content": error
            }]
        }></Flashbar>}
        {loading && <Spinner /> }
        {!loading && !error && brokerEnvironment &&
            <div>
                 <CopyToClipboardButton text={JSON.stringify(brokerEnvironment)} />
                <JSONPretty data={brokerEnvironment}></JSONPretty>
            </div>
        }
    </div>
    );
}

export default BrokerEnvironmentTab;
