import React, { memo } from 'react';
import { Broker } from '../types';
import * as Arn from "@aws-sdk/util-arn-parser";
import CloudWatchDashboard from './CloudWatchDashboard';
import { getRabbitChurnRatesMetrics } from './metrics/rabbitChurnRatesMetrics';

type Props = {
    broker: Broker,
}

const RabbitChurnAndRatesTab : React.FC<Props> = ({broker}) => {
    let region = Arn.parse(broker.brokerInfo.arn).region;
    let widgets = getRabbitChurnRatesMetrics(broker);

    return (
        <div>
            <CloudWatchDashboard
                region={region}
                layoutType='automatic'
                widgetsPerRow={2}
                serviceAccountId={broker.summary.serviceAccountId}
                widgets={widgets}
            /> 
        </div>
    )
}

export default memo(RabbitChurnAndRatesTab);