import React from 'react';
import { ColumnLayout } from '@amzn/awsui-components-react';
import './brokerAlarmsTable.css';
import CopyToClipboardButton from "./copyToClipboardButton";
import { BrokerAlarm } from './types';

type Props = {
    brokerAlarms: BrokerAlarm[]
}

const BrokerAlarmsTable : React.FC<Props> = ({brokerAlarms}) => {

    let hasAlarms = brokerAlarms.length > 0;
    let className = hasAlarms ? "broker-has-alarms" : "broker-no-alarms";

    return (
        <div className="awsui-util-container">
            <div className={`awsui-util-container-header ${className}`}>
                {hasAlarms && <h2>Broker Alarms</h2>}
                {!hasAlarms && <h2>No Broker Alarms</h2>}
            </div>
            {hasAlarms &&
                <ColumnLayout columns={3} variant="text-grid">
                    <div data-awsui-column-layout-root="true">
                        {brokerAlarms.map((brokerAlarm) => {
                            let alarmLink = brokerAlarm.link;
                            return (
                                <div key={brokerAlarm.name} style={{display: 'flex'}}>
                                    <a href={alarmLink} target="_blank">
                                        {brokerAlarm.name}
                                    </a>
                                    <CopyToClipboardButton text={brokerAlarm.name}/>
                                </div>
                            )
                        })}
                    </div>
                </ColumnLayout>
            }
        </div>
    )
}

export default BrokerAlarmsTable;
