import React, { useState } from 'react';

import { Broker, BrokerEngineType, Ec2Instance } from '../types';
import { Button } from '@amzn/awsui-components-react';
import EC2MetricsTab from './EC2MetricsTab';
import BrokerMetricsTab from './BrokerMetricsTab';
import RabbitChurnAndRatesTab from './RabbitChurnAndRatesTab';
import BrokerStorageTab from './BrokerStorageTab';
import RabbitQueuesTab from './RabbitQueuesTab';
import './styles.css';

type Props = {
    broker: Broker,
    brokerInstances: Ec2Instance[] | undefined,
}


const ServiceMetricsTab : React.FC<Props> = ({broker, brokerInstances}) => {

    const [activeTab, setActiveTab] = useState<string>('overview');

    if (brokerInstances === undefined) {
        return <></>
    }

    let activeTabStyles = {
        display: 'block'
    }

    let hiddenTabStyles = {
        display: 'none'
    }

    return (
        <>
            <div style={{marginBottom: 8}}>
                {broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ &&
                    <>
                        <Button className={activeTab === "overview" ? "selectedTab" : "unselectedTab"} onClick={() => setActiveTab('overview')}>Broker Metrics</Button>
                        <Button className={activeTab === "ec2s" ? "selectedTab" : "unselectedTab"}  onClick={() => setActiveTab('ec2s')}>Instance Metrics</Button>
                        <Button className={activeTab === "rates" ? "selectedTab" : "unselectedTab"}  onClick={() => setActiveTab('rates')}>Churn & Rates</Button>
                        <Button className={activeTab === "queues" ? "selectedTab" : "unselectedTab"}  onClick={() => setActiveTab('queues')}>Queues</Button>
                    </>
                }
                 {broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ &&
                    <>
                        <Button className={activeTab === "overview" ? "selectedTab" : "unselectedTab"} onClick={() => setActiveTab('overview')}>Broker Metrics</Button>
                        <Button className={activeTab === "ec2s" ? "selectedTab" : "unselectedTab"}  onClick={() => setActiveTab('ec2s')}>Instance Metrics</Button>
                        <Button className={activeTab === "broker-storage" ? "selectedTab" : "unselectedTab"}  onClick={() => setActiveTab('broker-storage')}>Broker Storage</Button>
                    </>
                }
            </div>
            <div>
                {broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ &&
                    <>
                        <div style={activeTab === 'overview' ? activeTabStyles : hiddenTabStyles}>
                            <BrokerMetricsTab broker={broker} brokerInstances={brokerInstances}/>
                        </div>
                        <div style={activeTab === 'ec2s' ? activeTabStyles : hiddenTabStyles}>
                            <EC2MetricsTab broker={broker} brokerInstances={brokerInstances}/>
                        </div>
                        <div style={activeTab === 'rates' ? activeTabStyles : hiddenTabStyles}>
                            <RabbitChurnAndRatesTab broker={broker} />
                        </div>
                        <div style={activeTab === 'queues' ? activeTabStyles : hiddenTabStyles}>
                            <RabbitQueuesTab broker={broker} brokerInstances={brokerInstances} />
                        </div>
                    </>
                }
                {broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ &&
                    <>
                        <div style={activeTab === 'overview' ? activeTabStyles : hiddenTabStyles}>
                            <BrokerMetricsTab broker={broker} brokerInstances={brokerInstances} />
                        </div>
                        <div style={activeTab === 'ec2s' ? activeTabStyles : hiddenTabStyles}>
                            <EC2MetricsTab broker={broker} brokerInstances={brokerInstances}/>
                        </div>
                        <div style={activeTab === 'broker-storage' ? activeTabStyles : hiddenTabStyles}>
                            <BrokerStorageTab broker={broker} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default ServiceMetricsTab;